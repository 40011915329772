.client-logo {
  height: 40px;
  min-width: 38px;
}

.icons {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}
.icons-data {
  display: flex;
  flex-wrap: wrap;
  column-gap: 2.0rem;
  row-gap: 1.5rem;
  filter: grayscale(1);
}

@media screen and (max-width: 620px) {

  .client-logo {
    height: 40px;
    width: 70px;
  }

}