.form-box-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    text-align: center;
    margin-top: 40px;

.form-box-header-heading {
    font-family: var(--font-family) !important;
    font-size: 50px !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: 0.4px !important;
    color: #11141c !important;
    margin-top: 1px !important;
}
.form-box-header-subheading-first {
    font-family: var(--font-family) !important;
    font-size: 24px !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8 !important;
    letter-spacing: 0.5px;
    color: #666666;
    margin-top: 12px;
    margin-bottom: 5px !important;

}
}

@media screen and (max-width:991px) {
    .form-box-header {
        .form-box-header-heading {
            font-size: 36px !important;
        }
    
        .form-box-header-subheading-first {
            font-size: 25px !important;
        }
    }
}

@media screen and (max-width:767px) {
    .form-box-header {
        padding-top: 20px;
        padding-bottom: 20px;
        margin: 0;
        .form-box-header-heading {
            font-size: 30px !important;
        }
    
        .form-box-header-subheading-first {
            font-size: 20px !important;
        }
    }
}

@media screen and (max-width:500px) {
    .form-box-header {
        .form-box-header-heading {
            font-size: 25px !important;
        }
    
        .form-box-header-subheading-first {
            font-size: 15px !important;
        }
    }
}