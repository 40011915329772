.college-form-box {
  .form {
    width: 80%;
    margin: auto;
    margin-top: 40px;

    .form-box {
      width: 100%;
      background-color: #FAFAFA;
      margin: 0 auto;
      border-radius: 8px;
      padding: 50px;

      .colab-section {
        margin: 0 auto;

        .colab {
          margin: 0 auto;
          width: 300px;
        }
      }

      .info-box-wrapper {
        margin: 0 auto;
        display: flex;
        column-gap: 50px;

        .contact-image {
          .left-img {
            .side-image {
              width: 100%;
              height: 370px;
              object-fit: cover;
              border-radius: 8px;
            }
          }
        }

        .contact-mail {
          display: flex;
          justify-content: flex-end;
          margin-top: 15px;

          .mail {
            font-family: var(--font-family);
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .email-icon {
            color: rgb(5, 5, 77);
            margin-right: 10px;
          }
        }

        .trusted-clients {
          border-left: 5px solid rgb(5, 5, 77);
          margin-top: 30px;
          padding-left: 10px;
          font-size: 20px;
          letter-spacing: 0.03px;
          color: #404942;
          font-family: var(--font-family) !important;
        }

        .clients {
          margin-top: 20px;
        }

        .application-form-box {
          width: 100%;
          margin: 0 auto;
        }
      }
    }

    .youtube-section {

      margin: 0 auto;
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: 1100px) {
  .info-box-wrapper {
    margin: 0 auto;
    display: flex;
    flex-direction: column-reverse;
    row-gap: 50px;
  }
}


@media screen and (max-width: 991px) {
  .college-form-box .form {
    .form-box {
      .info-box-wrapper .contact-image {
          .left-img {
            .side-image {
              width: 100%;
              height: 250px;
            }
          }
        }
    }
  }
}

@media screen and (max-width: 600px) {
  .college-form-box .form {
    width: 100%;
    margin: 0;

    .form-box {
      padding: 16px;
      .colab-section .colab {
        margin: 0 auto;
        width: auto;
      }
      .info-box-wrapper .contact-image {
          .left-img {
            .side-image {
              width: 100%;
              height: 220px;
              object-fit: cover;
            }
          }
        }
    }
  }
}