* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.thankyou-message {
  color: var(--primary-color);
  font-size: 1.5rem !important;
  font-weight: 600;
  padding: 0;
  line-height: 1;
}
.message {
  font-size: 1.15rem;
  line-height: 1;
  font-size: 300;
  color: #333;
}


.thankyou-head {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 24px;
  background-color: white;
  color: #118cfc;
  position: relative;
  padding-bottom: 3rem;
  padding-top: 2rem;
}

.thankyou-head img {
  height: 160px;
  // width: 180px;
  padding-top: 15px;
  padding-bottom: 15px;
  object-fit: cover;
}

.thankyou-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  height: 1.5em !important;
  width: 1.5em !important;
}

.youtube-icon {
  color: red;
  height: 1.5em !important;
  width: 1.5em !important;
}
.linkedin-icon {
  color: #118cfc;
  height: 1.5em !important;
  width: 1.5em !important;
  
}
.facebook-icon {
  color: #118cfc;
  height: 1.5em !important;
  width: 1.5em !important;
}
.instagram-icon {
  color: #e4405f;
  height: 1.5em !important;
  width: 1.5em !important;
}

.footer {
  width: 100%;
}

.thankyou-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow: hidden;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.thankyou-footer {
  width: 100%;
  height: 100%;
}
.footer-image {
  width: 100%;
  object-fit: cover;
  height: 100%;
}