.main {
    box-shadow: #118CFC 4px 2px 4px 2px;
}

.contact-page {
    margin: 7% 10% 2% 10%;
}

.first-para {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.first-para img {
    height: 150px;
    width: 150px;
}

.info {
    margin-left: 5% !important;
    margin-top: 10% !important;
    width: 30%;
}

.info-heading {
    font-weight: 600;
    font-size: 30px !important;
}

.icon {
    display: flex;
    margin-top: 3px;
    gap: 10px;
    padding-bottom: 15%;
}

.icon1 {
    display: flex;
    gap: 10px;
}

.icon2 {
    display: flex;
    gap: 10px;
}

@media screen and (max-width:767px) {
    .icon {
        display: flex;
        flex-direction: column;
    }
}

.info-icon {
    margin-left: 45%;
}

.first-para img {
    margin-right: 5% !important;
}

.info-icon-header {
    font-weight: 500;
    font-size: 24px !important;
}

.linkedin {
    color: #118CFC;
    height: 50px !important;
    width: 50px !important;
}

.youtube {
    color: red;
    height: 50px !important;
    width: 50px !important;
}

.facebook {
    color: #118CFC;
    height: 50px !important;
    width: 50px !important;
}

.instagram {
    color: #E4405F;
    height: 50px !important;
    width: 50px !important;
}