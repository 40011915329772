@import './assets/css/variable/style.scss';

.primary-color {
    color: $primary-color; 
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}