.text-center {
    text-align: center;
}

.fw-bold {
    font-weight: bold !important;
}

// Height
.h-100 {
    height: 100% !important;
}

.h-75 {
    height: 75% !important;
}

.h-50 {
    height: 50% !important;
}

.h-25 {
    height: 25% !important;
}

// Weight
.w-100 {
    width: 100% !important;
}

.w-75 {
    width: 75% !important;
}

.w-50 {
    width: 50% !important;
}

.w-25 {
    width: 25% !important;
}

// Margin 
.m-1 {
    margin: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

.m-5 {
    margin: 2rem !important;
}

.mx-1 {
    margin: 0rem 0.25rem !important;
}

.mx-2 {
    margin: 0rem 0.5rem !important;
}

.mx-3 {
    margin: 0rem 1rem !important;
}

.mx-4 {
    margin: 0rem 1.5rem !important;
}

.mx-5 {
    margin: 0rem 2rem !important;
}

.my-1 {
    margin: 0.25rem 0rem !important;
}

.my-2 {
    margin: 0.5rem 0rem !important;
}

.my-3 {
    margin: 1rem 0rem !important;
}

.my-4 {
    margin: 1.5rem 0rem !important;
}

.my-5 {
    margin: 2rem 0rem !important;
}

.mt-1 {
    margin-top: 0.25rem !important;
}

.mt-2 {
    margin-top: 0.5rem !important;
}

.mt-3 {
    margin-top: 1rem !important;
}

.mt-4 {
    margin-top: 1.5rem !important;
}

.mt-5 {
    margin-top: 2rem !important;
}

.mb-1 {
    margin-bottom: 0.25rem !important;
}

.mb-2 {
    margin-bottom: 0.5rem !important;
}

.mb-3 {
    margin-bottom: 20px !important;
}

.mb-4 {
    margin-bottom: 1.5rem !important;
}

.mb-5 {
    margin-bottom: 2rem !important;
}

.ml-1 {
    margin-left: 0.25rem !important;
}

.ml-2 {
    margin-left: 0.5rem !important;
}

.ml-3 {
    margin-left: 1rem !important;
}

.ml-4 {
    margin-left: 1.5rem !important;
}

.ml-5 {
    margin-left: 2rem !important;
}

.mr-1 {
    margin-right: 0.25rem !important;
}

.mr-2 {
    margin-right: 0.5rem !important;
}

.mr-3 {
    margin-right: 1rem !important;
}

.mr-4 {
    margin-right: 1.5rem !important;
}

.mr-5 {
    margin-right: 2rem !important;
}

// Padding 
.p-1 {
    padding: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.p-5 {
    padding: 2rem !important;
}

.px-1 {
    padding: 0rem 0.25rem !important;
}

.px-2 {
    padding: 0rem 0.5rem !important;
}

.px-3 {
    padding: 0rem 1rem !important;
}

.px-4 {
    padding: 0rem 1.5rem !important;
}

.px-5 {
    padding: 0rem 2rem !important;
}

.py-1 {
    padding: 0.25rem 0rem !important;
}

.py-2 {
    padding: 0.5rem 0rem !important;
}

.py-3 {
    padding: 1rem 0rem !important;
}

.py-4 {
    padding: 1.5rem 0rem !important;
}

.py-5 {
    padding: 2rem 0rem !important;
}

.pt-1 {
    padding-top: 0.25rem !important;
}

.pt-2 {
    padding-top: 0.5rem !important;
}

.pt-3 {
    padding-top: 1rem !important;
}

.pt-4 {
    padding-top: 1.5rem !important;
}

.pt-5 {
    padding-top: 2rem !important;
}

.pb-1 {
    padding-bottom: 0.25rem !important;
}

.pb-2 {
    padding-bottom: 0.5rem !important;
}

.pb-3 {
    padding-bottom: 1rem !important;
}

.pb-4 {
    padding-bottom: 1.5rem !important;
}

.pb-5 {
    padding-bottom: 2rem !important;
}

.pr-1 {
    padding-right: 0.25rem !important;
}

.pr-2 {
    padding-right: 0.5rem !important;
}

.pr-3 {
    padding-right: 1rem !important;
}

.pr-4 {
    padding-right: 1.5rem !important;
}

.pr-5 {
    padding-right: 2rem !important;
}

.pl-1 {
    padding-left: 0.25rem !important;
}

.pl-2 {
    padding-left: 0.5rem !important;
}

.pl-3 {
    padding-left: 1rem !important;
}

.pl-4 {
    padding-left: 1.5rem !important;
}

.pl-5 {
    padding-left: 2rem !important;
}