.header {
  position: relative;
  width: 100%;
  text-align: center;

  .header-image-wrapper {
    background-image: var(--banner-image);
    position: relative;
    display: inline-block;
    width: 100%;
    height: 320px;
    background-position: center;
    background-size: cover;

    .com-image {
      position: absolute;
      top: 30px;
      left: 30px;
      height: 80px;
      width: 80px;
    }

    .header-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 48px;
      font-family: var(--font-family) !important;
    }
  }

}

@media screen and (max-width:991px) {

  .header .header-image-wrapper {
    height: 250px;

    .header-text {
      font-size: 36px;
    }

    .com-image {
      height: 60px;
      width: 60px;
    }
  }
}

@media screen and (max-width:767px) {

  .header .header-image-wrapper {
    .header-text {
      font-size: 25px;
    }
  }
}

@media screen and (max-width:500px) {
  .header .header-image-wrapper {
    height: 180px;

    .com-image {
      height: 40px;
      width: 40px;
    }

    .header-text {
      font-size: 20px;
    }
  }
}

@media screen and (min-width:1536px) {
  .header .header-image-wrapper {
    height: 640px;
  }
}