.journey-box-header{
    text-align: center;
    margin-top: 50px;
    padding: 50px;
    .journey-box-header-heading{
        font-family: var(--font-family) !important;
        font-size: 50px !important;
        font-weight: bold !important;
        font-stretch: normal !important;
        font-style: normal !important;
        line-height: normal !important;
        letter-spacing: 0.4px !important;
        color: #11141c !important;
        margin-top: 1px !important;
    }
    .journey-box-header-subheading-first{
        font-family: var(--font-family) !important;
        font-size: 24px !important;
        font-weight: 500 !important;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8 !important;
        letter-spacing: 0.5px;
        color: #666666;
        margin-top: 12px;
        margin-bottom: 5px !important;
    }
}
@media screen and (max-width:991px) {
    .journey-box-header {
        .journey-box-header-heading {
            font-size: 36px !important;
        }
    
        .journey-box-header-subheading-first {
            font-size: 25px !important;
        }
    }
}

@media screen and (max-width:767px) {
    .journey-box-header {
        margin-top: 50px;
        .journey-box-header-heading {
            font-size: 30px !important;
        }
    
        .journey-box-header-subheading-first {
            font-size: 20px !important;
        }
    }
}

@media screen and (max-width: 600px) {
    .journey-box-header {
      padding: 16px;
    }
  }

@media screen and (max-width:500px) {
    .journey-box-header {
        .journey-box-header-heading {
            font-size: 25px !important;
        }
    
        .journey-box-header-subheading-first {
            font-size: 15px !important;
        }
    }
}