.colab-header{
    margin: 0 auto;
    height: 79px;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    .image-1{
        object-fit: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        .college-image, .comp-img{
            height: 70px;
            width: 70px;
            border-radius: 100%;
            object-fit: cover;
        }
        .collabration-logo{
            width: 70px;
            height: 40px;
        }
    }
}


@media screen and (max-width: 400px) {
    .colab-header{
        margin: 0 auto;
        height: 79px;
        display: flex;
        gap: 15px;
        align-items: center;
        justify-content: center;
        .image-1{
            .imge{
                width: 50px;
                height: 50px;
            }
            .collabration-logo{
                width: 40px;
                height: 30px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .colab-header{
        margin: 0 auto;
        height: 79px;
        display: flex;
        gap: 25px;
        align-items: center;
        justify-content: center;
        .image-1{
            .imge{
                width: 60px;
                height: 60px;
            }
            .collabration-logo{
                width: 40px;
                height: 30px;
            }

        }
    }
  
}