

.upload {
    color: rgb(118, 118, 118) !important;
    margin-bottom: 3px !important;
}

.upload-dropzone {
    background-color: #F5F5F5;
    border: 1px dashed #999999;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    color: #858383;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-family) !important;
}

.submit-btn {
    text-align: right;
}

.upload-resume{
    color: var(--primary-color);
    text-decoration:underline;
}

.check-box{
    display: flex;
    .check-box-data{
        font-family: var(--font-family) !important;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
    }
}

.check-box p{
    margin-top: 12px;
    margin-left: 5px;
    letter-spacing: 0.16px;
    color: #0C1F14;
}