.vid-container {
  padding-bottom: 40px;

  .video-responsive {
    .frame-container {
      width: 100%;
      height: 600px;
      border-radius: 6px;
    }

    overflow: hidden;
    position: relative;
    border-radius: 8px;

    .navigation-buttons {
      button {
        border: none;
        outline: none;
        background-color: transparent;
        cursor: pointer;
      }

      .prev,
      .next {
        position: absolute;
        top: 44%;

        img {
          height: 40px;
          width: 40px;
        }
      }

      .prev {
        left: 2%;
      }

      .next {
        right: 2%;
      }
    }

  }
}


@media (max-width: 1199px) {
  .vid-container {
    .video-responsive {
      .frame-container {
        height: 450px;
      }
    }
  }
}

@media (max-width: 991px) {
  .vid-container {
    .video-responsive {
      .frame-container {
        height: 342px;
      }
    }
  }
}

@media (max-width: 767px) {
  .vid-container {
    .video-responsive {
      .frame-container {
        height: 300px;
      }
    }
  }
}

@media (max-width: 600px) {
  .vid-container {
    padding: 0 12px;
    padding-bottom: 40px;
  }
}

@media (max-width: 510px) {
  .vid-container {
    .video-responsive {
      .frame-container {
        height: 210px;
      }
    }
  }
}

@media (max-width: 400px) {
  .vid-container {
    padding: 0 16px;
    padding-bottom: 40px;

    .video-responsive {
      .frame-container {
        height: 180px;
      }
    }
  }
}