.snackbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 4px;

  &.mobile {
    padding: 8px 16px;
  }

  &.desktop {
    padding: 12px 24px;
  }

  &.warning {
    background-color: #ff9800;
  }

  &.error {
    background-color: #f44336;
  }

  &.success {
    background-color: #4caf50;
  }

  &.info {
    background-color: #118cfc;
  }

  .snackbar-content {
    display: flex;
    align-items: center;
    gap: 8px;

    .message {
      word-break: break-word;
      color: #fff;
  
      &.mobile {
        font-size: 14px;
      }
  
      &.desktop {
        font-size: 16px;
      }
    }
  }
  .close-button {
    font-size: 1rem;
  }
}
