.custom-number {
    display: flex;
    border-radius: 3px;
}

.flag-img {
    width: 22px;
    margin: 0px 5px;
}
.dialCode{
    border-radius: 4px 0px 0px 4px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.radius > div:first-of-type {
    border-radius:0px 4px 4px 0px;
}